<template functional>
  <div class="footer">
    <div class="container">
      <div class="columns">
        <div class="column is-two-fifths has-text-centered flex-div">
          <div>
            <img width="100" height="100" src="../assets/logo.svg" alt="logo" />
          </div>
        </div>
        <div class="column is-one-fifth">
          <p class="heading">Meer lezen</p>
          <p>
            <router-link to="/study-buddy">StudyBuddy</router-link>
          </p>
          <p>
            <router-link to="/blog">Blog</router-link>
          </p>
          <p>
            <router-link to="/woordjes-leren">Woordjes leren</router-link>
          </p>
          <p>
            <router-link to="/quizlet-alternatief">Quizlet alternatief</router-link>
          </p>
        </div>
        <div class="column is-one-fifth">
          <p class="heading">Account</p>
          <p>
            <router-link to="/login">Inloggen</router-link>
          </p>
          <p>
            <router-link to="/aanmelden">Registreren</router-link>
          </p>
          <p>
            <router-link to="/requestpassword">Wachtwoord vergeten</router-link>
          </p>
          <p>
            <router-link to="/av.pdf" target="_blank">Algemene voorwaarden</router-link>
          </p>
          <p>
            <router-link to="/pp.pdf" target="_blank">Privacy verklaring</router-link>
          </p>
        </div>
        <div class="column is-one-fifth">
          <p class="heading">Service</p>
          <p>
            <router-link to="/contact">Contact</router-link>
          </p>
        </div>
      </div>
      <p class="has-text-centered">Copyright © Ikleeralles.nl | KVK 73481998</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "footercomp",
}
</script>

<style lang="scss" scoped>
hr {
  background: rgba(0, 0, 0, 0.2);
  margin: 0.5em 0;
  height: 1px;
}
.footer {
  background: #fff;
  padding: 50px 0 10px 0;
  margin-top: auto;
  .columns {
    margin-bottom: 50px;
  }
  p {
    padding: 5px 0;
    font-size: 1rem;
    &.heading {
      color: #000;
      font-size: 1.3rem;
      font-weight: 600;
      padding: 10px 0;
    }
  }
  a {
    color: #737373;
    &:hover {
      color: #000;
    }
  }
}
</style>
